import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import FeedsCard from "components/flujograma/FeedsCard.js";
import SlideshowCard from "components/flujograma/SlideshowCard.js";
import BaseCard from "components/flujograma/BaseCard.js";
import YoutubeEmbed from "components/general/YoutubeEmbed.js";
import LoadingIndicator from "components/general/LoadingIndicator.js";

import styles from "assets/jss/primaryScreenstyle.js";

import axios from 'axios';
import ApiURLs from "config/ApiURLs.js";
import { Link } from "react-router-dom";
import D3BarChart from "components/indicadores/D3BarChart.jsx";
import SlideshowCardNew from "components/indicadores/SlideshowCard.jsx";
import BaseScreenIndicadores from "./BaseScreen";
import D3Bar from "components/indicadores/D3Bar";
import Engranajes from "components/indicadores/Engranajes.jsx";

const useStyles = makeStyles(styles);

export default function PrimaryScreenIndicadores(props) {
    //const appContext = React.useContext(AppContext);
    //const rootRef = React.useRef(null);
    let baseYear = (new Date().getFullYear()) - 1;
    const classes = useStyles();
    //const [searchValue, setSearchValue] = React.useState('');

    const [videoList, setVideoList] = React.useState({ isLoading: true, data: { 'name': '...' } /*remove fakeData once backend is ready*/ });
    const [chart1, setChart1] = React.useState({ isLoading: true, data: {} });
    const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/video-list?id=vl001').then((data) => {
            setVideoList({ isLoading: false, data: data.data });
        });
        axios.get(ApiURLs.baseURL + 'website/chart?id=OJ-BAR-001&year=2023').then((data) => {
            setChart1({ isLoading: false, data: data.data });
        });
    }, []);


    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    React.useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    React.useEffect(() => {
        // Create meta elements
        const cacheControlMeta = document.createElement('meta');
        cacheControlMeta.httpEquiv = 'cache-control';
        cacheControlMeta.content = 'no-cache';

        const expiresMeta = document.createElement('meta');
        expiresMeta.httpEquiv = 'expires';
        expiresMeta.content = '0';

        const pragmaMeta = document.createElement('meta');
        pragmaMeta.httpEquiv = 'pragma';
        pragmaMeta.content = 'no-cache';

        // Add meta elements to the document head
        document.head.appendChild(cacheControlMeta);
        document.head.appendChild(expiresMeta);
        document.head.appendChild(pragmaMeta);

        // Cleanup: remove meta elements when the component unmounts
        return () => {
            document.head.removeChild(cacheControlMeta);
            document.head.removeChild(expiresMeta);
            document.head.removeChild(pragmaMeta);
        };
    }, []);

    /* const handleChange = (newValue) => {
        setSearchValue(newValue);
    }; */
    return (
        <BaseScreenIndicadores indicadoresHeader bodyColor="#f2f3f6">
            <div>
                <Typography variant="h1" className={classes.title}>Indicadores de la Justicia Criminal - Guatemala</Typography>
                <div className={classes.subtitleContainer}>
                    <Typography variant="h2" className={classes.subtitle}>Hacia un sistema de justicia transparente, eficiente y digital.</Typography>
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                    {
                        videoList.isLoading ? <LoadingIndicator /> : <FeedsCard newsFeeds={videoList.data.videos} title={'VIDEOS'} buttons={[]} variant='videolist' />
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    {
                        chart1.isLoading ? <LoadingIndicator /> : <SlideshowCardNew slideElements={[{ main: <D3Bar width={700} height={(screenSize.width < 1270 && screenSize.width >= 960) ? (245 + (90 * ((1270 - screenSize.width) / 310))) : 240} source={chart1.data.data} requiresLegends2 type={6} />, title: 'Casos ingresados y egresados en el OJ', subtitle: 'Años 2014-2024', chartId: 'OJ-BAR-001&year=2024', displayonflujograma: chart1.show_on_flujogram }]} />
                    }
                    {/* {
                        mainChart.isLoading ? <LoadingIndicator /> : <SlideshowCard slideElements={[{ main: <D3LinearChart width={700} height={240} source={mainChart.data.data} isPreview />, title: 'Eficiencia Global del Sistema', subtitle: 'La eficiencia global del sistema a través de los años', chartId: 'C-GLOBAL-004' }]} />
                    } */}
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4}>
                    <MediaCard title={'Lanzamiento de la plataforma'} subtitle={'05 octubre 2021'} image={invitationImage} />
                </Grid> */}

                <Grid item xs={12} sm={6} md={6} style={{ marginBottom: '1.3rem', minHeight: '270px' }}>
                    <BaseCard height="100%">
                        <YoutubeEmbed embedId="C91mjggoPXs" />
                    </BaseCard>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {
                        videoList.isLoading ? <LoadingIndicator /> :
                            <BaseCard height="auto">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', width: "auto", padding: '1.5rem' }}>
                                    <Engranajes history={props.history} goTo={{
                                        MP: "/dashboard/2",
                                        OJ: "/dashboard/1",
                                        IDPP: "/dashboard/4",
                                        SP: "/dashboard/5",
                                        INACIF: "/dashboard/3"
                                    }} />
                                </div>
                            </BaseCard>
                    }
                </Grid>
            </Grid>
        </BaseScreenIndicadores>
    )
}
